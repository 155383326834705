import React from "react";

import Footer from "components/Footer";
import Header from "components/Header";
import PageSizeContent from "components/PageSizeContent";
import Scaffold from "components/Scaffold";
import TextTuple from "components/TextTuple";
import useModuleDetail from "containers/useModuleDetail";
import Divider from "components/Divider";
import LangText from "components/LangText";
import { dateToString } from "utils/transform/date";
import ImageServer from "components/Image/ImageServer";
import Title from "components/Title";
import TextareaDisplay from "components/TextareaDisplay";
import {
  captionTranslate,
  getBothLangContent,
} from "utils/transform/multilang";
import useModuleListing from "containers/useModuleListing";
import _, { isEmpty } from "lodash";
import useAdhocDetail from "containers/useAdhocDetail";

type Props = {};

const SummaryAdhocPage = (props: Props) => {
  const { data } = useAdhocDetail();
  const { data: clientData } = useModuleDetail("client", data?.value?.client);
  const { data: supervisorData } = useModuleDetail(
    "cms_user",
    data?.value?.cms_user
  );
  const { data: techData } = useModuleDetail(
    "technician",
    data?.value?.technician
  );
  const { data: facilityData } = useModuleDetail(
    "facility",
    data?.value?.facility
  );
  const { data: inspectorData } = useModuleDetail(
    "cms_user",
    data?.value?.report_inspector
  );

  const contactIds =
    !_.isEmpty(data?.value?.contact_ids) && _.isArray(data?.value?.contact_ids)
      ? data?.value?.contact_ids
      : [facilityData?.value?.contacts?.[0]].filter((s) => !!s);
  const { data: contactDataListRes } = useModuleListing("contact", contactIds);
  const contactDataList =
    (!isEmpty(data?.value?.contacts)
      ? data?.value?.contacts
      : contactDataListRes?.value) || [];

  const address = data?.value?.address || facilityData?.value;

  return (
    <PageSizeContent size="a4" className="page_summary" isAdhoc>
      <Scaffold
        header={
          <Header
            title="SERVICE REPORT SUMMARY"
            zhTitle="臨時服務總結報告"
            subTitle={undefined}
            zhSubTitle={undefined}
          />
        }
        footer={<Footer isAdhoc />}
      >
        <TextTuple
          title="Client Company"
          zhTitle="公司"
          value={
            clientData?.value?.client_name || (
              <LangText>{clientData?.value?.company_name}</LangText>
            )
          }
          className="pb-4"
        />
        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
          <TextTuple
            title="Attn"
            zhTitle="致"
            value={contactDataList.map((s) => s.name).join(" / ")}
          />
          <TextTuple
            title="P.O No."
            zhTitle="訂單編號"
            value={data?.value?.report_po_no}
          />
          <TextTuple
            title="Tel"
            zhTitle="電話"
            value={contactDataList
              .map(
                (s) =>
                  `${s.phone_region_code ? `+${s.phone_region_code} ` : ""}${
                    s.phone || ""
                  }`
              )
              .join(" / ")}
          />
          <TextTuple
            title="Project No."
            zhTitle="項目編號"
            value={data?.value?.report_project_no}
          />
          <TextTuple
            title="Email"
            zhTitle="電郵"
            value={contactDataList.map((s) => s.email).join(" / ")}
          />
          <TextTuple
            title="Reference Number."
            zhTitle="參考編號"
            value={data?.value?.report_ref_no}
          />
        </div>
        <Divider />
        <TextTuple
          title="Project"
          zhTitle="項目名稱"
          value={
            isEmpty(data?.value?.report_title) ? (
              data?.value?.title
            ) : (
              <LangText>{data?.value?.report_title}</LangText>
            )
          }
          className="pb-4"
        />
        <TextTuple
          title="Project Address"
          zhTitle="項目地址"
          value={
            data?.value?.report_address ||
            [
              address?.address_line_1,
              address?.address_line_2,
              address?.address_line_3,
            ]
              .filter((s) => !!s)
              .map((s) => captionTranslate(s))
              .join(", ")
          }
          className="pb-4"
        />
        {!!facilityData?.value?.name && (
          <TextTuple
            title="Service Location"
            zhTitle="工作地址"
            value={captionTranslate(facilityData?.value?.name)}
            className="pb-6"
          />
        )}
        {/* Report Summary*/}
        <div className="pt-5">
          <Title title="Report Summary" zhTitle="報告總結" size="sm" />
          <TextareaDisplay html={getBothLangContent(data?.value?.summary)} />
        </div>
        <div className="pt-5">
          <Title title="Recommendation" zhTitle="建議" size="sm" />
          <TextareaDisplay
            html={getBothLangContent(data?.value?.recommendation)}
          />
        </div>
        {/* Bottom Part, signature, responsible people, group in 1 div, to better page split */}
        <div>
          <div className="grid grid-cols-2 gap-4 pt-8">
            <TextTuple
              title="Maintenance Technician"
              zhTitle="保養技術員"
              value={techData?.value?.name || supervisorData?.value?.name}
            />
            <TextTuple
              title="Inspector / Engineer"
              zhTitle="檢查人員"
              value={inspectorData?.value?.name}
            />
            <TextTuple
              title="Date &amp; Time"
              zhTitle="日期和時間"
              value={dateToString(
                data?.value?.report_datetime || data?.value?.start_datetime,
                "datetime"
              )}
            />
          </div>
          {data?.value?.signature && (
            <TextTuple
              title="Technician Signature"
              zhTitle="技術員簽名"
              value=""
              className="pt-4 !items-start"
            >
              <ImageServer
                displayHeight={87}
                displayWidth={174}
                uuid={data.value.signature}
                module="ad_hoc_service"
                id={data?.value?.id}
              />
            </TextTuple>
          )}
          {data?.value?.technician_signature && (
            <TextTuple
              title="Client Signature"
              zhTitle="客戶代表簽名"
              value=""
              className="pt-4 !items-start"
            >
              <ImageServer
                displayHeight={87}
                displayWidth={174}
                uuid={data.value.technician_signature}
                module="ad_hoc_service"
                id={data?.value?.id}
              />
            </TextTuple>
          )}
        </div>
      </Scaffold>
    </PageSizeContent>
  );
};

export default SummaryAdhocPage;
