/* eslint-disable eqeqeq */
import Footer from "components/Footer";
import Header from "components/Header";
import PageSizeContent from "components/PageSizeContent";
import Scaffold from "components/Scaffold";
import React from "react";

import useModuleDetail from "containers/useModuleDetail";
import TextareaDisplay from "components/TextareaDisplay";
import qs from "query-string";

import useProductionQuestionDetail from "components/ProductionQuestionDetail/useProductionQuestionDetail";
import useProductQuestionDetailImage from "components/ProductionQuestionDetail/useProductQuestionDetailImage";
import styled from "styled-components";
import { getBothLangContent } from "utils/transform/multilang";
import useAdhocDetail from "containers/useAdhocDetail";

const ProductAdhocPage = () => {
  const url = qs.parse(window.location.search);

  const isA3 = url.a3 === "1";
  const { data } = useAdhocDetail();

  const { data: repliesData } = useModuleDetail(
    "poly_data",
    data?.value?.poly_data
  );
  const replies = [
    repliesData?.value
      ? {
          ...repliesData?.value,
          equipmentData: {
            custom_identifier: data?.value?.equipment_custom_identifier,
          },
        }
      : undefined,
  ].filter((s) => !!s);

  // Find product setting
  const remarksBreaker = getBothLangContent(data?.value?.remarks)?.replace(
    /\n\n|\n/g,
    ""
  );

  const polyModelVid = repliesData?.value?.poly_model;

  const renderProductQuestionDetailImage = useProductQuestionDetailImage({
    joinedData: replies,
    polyModelVid,
    replies: replies,
    useLoopImageQuestionFirst: undefined,
  });

  const renderProductionQuestionDetail = useProductionQuestionDetail({
    polyModelVid,
    replies: replies,
  });

  return (
    <PageSizeContent
      className={`page_product product_adhoc`}
      size={isA3 ? "a3" : "a4"}
      horizontal={isA3 ? true : false}
      isAdhoc
    >
      <StyledScaffold
        header={
          <Header
            title={`SERVICE REPORT`}
            zhTitle={`臨時服務報告`}
            subTitle={undefined}
            zhSubTitle={undefined}
          />
        }
        footer={<Footer isAdhoc />}
      >
        {renderProductionQuestionDetail(false, undefined)}
        {renderProductQuestionDetailImage()}
        {!!data?.value?.remarks && !!remarksBreaker && (
          <div className="mt-4">
            <TextareaDisplay html={remarksBreaker} remarksTitle asrisk />
          </div>
        )}
      </StyledScaffold>
    </PageSizeContent>
  );
};

const StyledScaffold = styled(Scaffold)`
  .c-table tbody.hidden.header {
    display: table-row-group;
    tr:last-child {
      display: none;
    }
  }

  tbody:not(.header) .sub-row-header.row-component .lg-title {
    visibility: hidden;
  }

  .last-ans-row-parent:not(.hidden) + tbody > .sub-row-header > td > div {
    padding-top: 9999px !important;
  }

  tbody.last-ans-row-parent:last-of-type + tfoot {
    display: table-footer-group !important;
  }

  .scaffold > div.hidden + div:not(.hidden) {
    // Margin top for 1st element c table
    & > .c-table {
      margin-top: 8px;
    }

    & > .c-table .c-table_title {
      padding-top: 0px !important;
    }
  }
`;

export default ProductAdhocPage;
