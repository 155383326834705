import useAdhocDetail from "containers/useAdhocDetail";
import useTaskDetail from "containers/useTaskDetail";
import React, { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import usePreview from "utils/hooks/usePreview";

type Props = {
  size: "a4" | "a3";
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  horizontal?: boolean;
  isAdhoc?: boolean;
};

const PageSizeContent = ({
  children,
  className,
  style,
  size,
  horizontal = false,
  isAdhoc,
}: Props) => {
  const isPreview = usePreview();
  const { data: taskData } = useTaskDetail(!isAdhoc);
  const { data: adhocData } = useAdhocDetail(!isAdhoc);
  const data = isAdhoc ? adhocData : taskData;

  return (
    <div
      className={
        isPreview
          ? "bg-[#999] h-[100vh] flex items-center justify-center py-10"
          : undefined
      }
    >
      {data?.value?.report_style && <style>{data.value.report_style}</style>}
      <PageSizeContentWrapper
        className={className}
        style={style}
        size={size}
        horizontal={horizontal}
      >
        {children}
      </PageSizeContentWrapper>
    </div>
  );
};

const PageSizeContentWrapper = styled.div<Pick<Props, "size" | "horizontal">>`
  ${(props) => {
    if (props.size === "a4") {
      if (props.horizontal) {
        return "width: 1123px; height: 794px;";
      } else {
        return "width: 794px; height: 1123px;";
      }
    }
    if (props.size === "a3") {
      if (props.horizontal) {
        // Slight less height to prevent over page
        return "width: 1586px; height: 1120px;";
      } else {
        return "width: 1123px; height: 1583px;";
      }
    }
  }}
  position: relative;
  overflow: hidden;
  background: #fff;
`;

export default PageSizeContent;
